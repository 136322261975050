import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_block = _resolveComponent("header-block")!
  const _component_three_steps_block = _resolveComponent("three-steps-block")!
  const _component_map_block = _resolveComponent("map-block")!
  const _component_feature_block = _resolveComponent("feature-block")!
  const _component_banner_block = _resolveComponent("banner-block")!
  const _component_economy_block = _resolveComponent("economy-block")!
  const _component_footer_block = _resolveComponent("footer-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_header_block, { link: _ctx.registrationLink }, null, 8, ["link"]),
    _createVNode(_component_three_steps_block),
    _createVNode(_component_map_block, { link: _ctx.registrationLink }, null, 8, ["link"]),
    _createVNode(_component_feature_block),
    _createVNode(_component_banner_block, { link: _ctx.registrationLink }, null, 8, ["link"]),
    _createVNode(_component_economy_block, { link: _ctx.registrationLink }, null, 8, ["link"]),
    _createVNode(_component_footer_block)
  ]))
}