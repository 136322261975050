
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FooterBlock',
  data() {
    return {
      host: window.location.host,
    };
  },
  computed: {
    isProd(): boolean {
      return this.host === 'b2b.fuelup.ru';
    },
  },
});
