
import { defineComponent } from 'vue';
import SvgMap from '@/components/SvgMap.vue';

export default defineComponent({
  name: 'MapBlock',
  components: { SvgMap },
  props: {
    link: String,
  },
});
