
import { defineComponent } from 'vue';
import HeaderBlock from '@/views/blocks/HeaderBlock.vue';
import ThreeStepsBlock from '@/views/blocks/ThreeStepsBlock.vue';
import MapBlock from '@/views/blocks/MapBlock.vue';
import FeatureBlock from '@/views/blocks/FeatureBlock.vue';
import EconomyBlock from '@/views/blocks/EconomyBlock.vue';
import BannerBlock from '@/views/blocks/BannerBlock.vue';
// import ControlBlock from '@/views/blocks/ControlBlock.vue';
// import MobileAppBlock from '@/views/blocks/MobileAppBlock.vue';
// import RegistrationBlock from '@/views/blocks/RegistrationBlock.vue';
// import ConsultBlock from '@/views/blocks/ConsultBlock.vue';
import FooterBlock from '@/views/blocks/FooterBlock.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    FooterBlock,
    // ConsultBlock,
    // RegistrationBlock,
    // MobileAppBlock,
    // ControlBlock,
    BannerBlock,
    EconomyBlock,
    FeatureBlock,
    MapBlock,
    ThreeStepsBlock,
    HeaderBlock,
  },
  data() {
    const isProd = window.location.host === 'b2b.fuelup.ru';
    return {
      registrationLink: `https://${isProd ? '' : 'stage.'}new-lk.fuelup.ru/registration?utm_source=corp_landing`,
    };
  },
});
