
import { defineComponent } from 'vue';
import SvgLogo from '@/components/SvgLogo.vue';

export default defineComponent({
  name: 'HeaderBlock',
  components: { SvgLogo },
  props: {
    link: String,
  },
});
