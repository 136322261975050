
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ThreeStepsBlock',
  data() {
    return {
      items: [
        {
          title: 'Пополните баланс с&nbsp;бизнес&#8209;карты Сбера',
          note: 'Вам моментально будет зачислено вознаграждение&nbsp;5% от&nbsp;суммы пополнения',
        },
        {
          title: 'Добавьте водителей по&nbsp;номеру телефона',
          note:
            'Мобильное приложение FuelUp доступно для&nbsp;<a href="https://play.google.com/store/apps/details?id=ru.ips.fuelup" ' +
            'rel="nofollow,noopener" target="_blank">Android</a> и&nbsp;<a href="https://apps.apple.com/ru/app/fuelup/id1212049348" ' +
            'rel="nofollow,noopener" target="_blank">iOS</a>&nbsp;устройств',
        },
        {
          title: 'Заправляйтесь виртуальной топливной картой FuelUp',
          note: 'Оплачивайте топливо виртуальной топливной картой со&nbsp;скидкой&nbsp;1,5% на&nbsp;всех&nbsp;АЗС',
        },
      ],
    };
  },
});
