
import { defineComponent } from 'vue';

import { ampli } from '@/ampli';

import FingerprintJS from '@fingerprintjs/fingerprintjs';

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load();

(async () => {
  // Get the visitor identifier when you need it.
  const fp = await fpPromise;
  const { visitorId } = await fp.get();
  // console.log(result.visitorId);

  ampli.load({ environment: process.env.NODE_ENV === 'development' ? 'development' : 'production' });
  ampli.identify(visitorId);
  ampli.newUserLanding();
})().catch(() => null);

export default defineComponent({
  data() {
    return {
      host: window.location.host,
      cookieVisible: false,
    };
  },
  created() {
    this.cookieVisible = localStorage.getItem('_fuelup_cookie_agree') !== '1';
  },
  computed: {
    isProd(): boolean {
      return this.host === 'b2b.fuelup.ru';
    },
  },
  methods: {
    agree() {
      this.cookieVisible = false;
      localStorage.setItem('_fuelup_cookie_agree', '1');
    },
  },
});
